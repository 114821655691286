// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.visibilityCheck {
//.completeVisibilityCheck {
    & > div:nth-child(1) {
        transform: translateX(-150%);
        transition: all .5s ease-out;
    }
    & > div:nth-child(2) {
        transform: translateX(150%);
        transition: all .5s ease-out;
    }
    &.isVisible {
    //&.isCompletelyVisible{
        & > div:nth-child(1), & > div:nth-child(2) {
            transform: translateX(0);
            transition: all .5s ease-out;
        }
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

@import 'sections/gallery-products';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* POP-UP
*******************************************************************************/

div.md-modal {
    .md-content {
        h3 {
            font-weight: 900;
            font-size: $font-size-36;
            color: #ed7700;
            text-transform: initial;
        }
    }
}



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocOrange {
    background: url("../images/accueil_section01_bg.jpg") no-repeat;
    padding: 40px 2% 40px 23%;
    background-size: cover;
    .texte {
        p {
            font-weight: normal;
            color: #fff;
            font-size: $font-size-20;
            line-height: 1.5;
        }
    }
    .texte.first {
            padding-bottom: 20px;
    }
}

#blocBleu {
    background: url("../images/accueil_section02_bg.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment : fixed;
    background-position: right;
    min-height: 740px;
    padding-top: 25px;
    position: relative;
    a.btn-equipement {
        border: 1px solid #444444;
        padding: 25px;
        width: 55%;
        text-align: center;
        margin: auto;
        display: flex;
        justify-content: center;

        font-weight: normal;
        font-size: $font-size-28;
        color: #444444;
        span.defaut {
            padding-left: 20px;
        }
        span.hover {
            opacity: 0;
        }
        &:hover {
            transition: 0.3s;
            background: #fff;
            //font-weight: 900;
            color: #38baf5;
            span.hover {
                opacity: 1 !important;
                margin-left: -2px;
            }
        }
    }
    .bloc {
        display: flex;
        align-items: center;
        padding: 50px 2%;
        position: absolute;
        left: 18%;
        .left-side {
            padding-right: 65px;
            padding-top: 19%;
        }
        .right-side {
            p {
                line-height: 1;
            }
            .bottom {
                padding-top: 30px;
            }
        }
    }
    h3 {
        font-weight: 900;
        font-size: $font-size-26;
        color: #444444;
        padding-bottom: 30px;
        text-transform: initial;
    }
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #444444;
        line-height: 1.5;
        span {
            padding-right: 15px;
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#blocIntro_services {
    background: url(../images/services_section01_bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    min-height: 353px;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 30px;
    padding-left: 6%;
    .left-side {
        width: 30%;
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #444444;
            line-height: 1.5;
            margin: 0;
            span {
                font-size: $font-size-24;
            }
        }
    }
    .right-side {
        a {
            .texte-image {
                display: flex;
                align-items: center;
                h4 {    
                    font-weight: normal;
                    font-size: $font-size-28;
                    color: #444444;
                }
                h3 {
                    font-weight: bold;
                    font-size: $font-size-30;
                    color: #444444;
                    span {
                        padding-left: 15px;
                    }
                }
                .image {
                    img.img-responsive.hover {
                        display: none;
                    }
                }
            }
            &:hover {
                .image {
                    img.img-responsive.hover {
                        display: block;
                        transition: 0.3s;
                    }
                    img.img-responsive.defaut {
                        display: none;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}

#blocServices {
    background: url(../images/services_section02_bg.jpg) no-repeat;
    background-position: top;
    min-height: 253px;
    padding: 10px 14%;
    h3 {
        font-weight: 900;
        font-size: $font-size-26;
        color: #fff;
        text-transform: initial;
        padding-bottom: 25px;
    }
    .blocliste {
        display: flex;
        align-items: center;
        .left {
            width: 75%;
        }
        ul {
            position: relative;
            li {
                font-weight: normal;
                font-size: $font-size-26;
                color: #fff;
                line-height: 1.3;
                text-transform: initial;
                list-style: none;
                padding-bottom: 5px;
                &::before {
                    content: '>';
                    position: absolute;
                    left: 0;
                }
            }
        }
    }
}

#blocBleu_services {
    background: url("../images/accueil_section02_bg.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment : fixed;
    background-position: right;
    min-height: 740px;
    padding-top: 25px;
    position: relative;
    .bloc {
        display: flex;
        padding: 50px 2%;
        position: absolute;
        left: 18%;
        .left-side {
            padding-right: 65px;
            .texte {
                padding-bottom: 25px;
            }
        }
        .right-side {
            p {
                line-height: 1;
                font-size: $font-size-26;
            }
            .bottom {
                padding-top: 30px;
            }
        }
    }
    h3 {
        font-weight: 900;
        font-size: $font-size-26;
        color: #444444;
        padding-bottom: 30px;
        text-transform: initial;
    }
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #444444;
        line-height: 1.5;
        span {
            padding-right: 15px;
        }
    }
}

/*******************************************************************************
* NOS ÉQUIPEMENTS
*******************************************************************************/

#titrePage {
    position: absolute;
    top: 50%;
    left: 14%;
    h2 {
        font-weight: 900;
        font-size: $font-size-48;
        color: #fff;
        text-transform: uppercase;
    }
}

#blocIntro_equipements {
    background: url(../images/equipements_section01_bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 350px;
    padding: 0 10%;
    .left-side {
        width: 45%;
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #fff;
            line-height: 1.3;
            margin: 0;
            span {
                font-size: $font-size-24;
            }
        }
    }
    .right-side {
        a {
            .btnEquip {
                padding: 20px 180px;
                background: #38baf5;
                position: relative; 
                p {
                    font-weight: normal;
                    font-size: $font-size-30;
                    color: #fff;
                    text-align: center;
                    line-height: 1.2;
                    span {
                        position: absolute;
                        right: 17%;
                        top: 48%;
                    }
                }
            }
        }
    }
}

#blocEquipement {
    background: url(../images/equipements_section02_bg.jpg) no-repeat;
    background-position: top;
    display: flex;
    justify-content: space-around;
    padding: 100px 0 50px 0;
    .image {
        padding-bottom: 40px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    .titre {
        h3 {
            font-weight: 900;
            font-size: $font-size-26;
            color: #444444;
            text-transform: initial;
        }
    }
    .bloc {
        padding-bottom: 35px;
        .info {
            padding: 15px 0 5px 0;
        }
        h3 {
            font-weight: 900;
            font-size: $font-size-26;
            color: #444444;
            text-transform: initial;
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: #444444;
            line-height: 1.3;
            margin: 0;
        }
        ul {
            padding-left: 0;
            li {
                font-weight: normal;
                font-size: $font-size-24;
                color: #444444;
                line-height: 1.3;
                list-style: none;
                text-transform: initial;
                span {
                    padding-right: 25px;
                }
            }
        }
    }
    .bloc.blocListe {
        padding-top: 15%;
    }
    .bloc.blocListe2 {
        padding-top: 28%;
    }
}

#blocLogo {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5%;
    .logo {
        width: 25%;
        padding: 25px;
        .img-responsive {
            margin: auto;
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#blocRealisations {
    background: url(../images/realisations_section01_bg.jpg) no-repeat;
    padding: 0 0 50px 0;
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocCoordonnees {
    background: url(../images/coordonnees_section01_bg_long.jpg) no-repeat;
    background-position: top;
    display: flex;
    padding: 100px 5%;
    .left-side {
        width: 50%;
        .bloc {
            display: flex;
            align-items: center;
            padding-bottom: 50px;
            .icon {
                width: 18%;
            }
            .jours-heures {
                display: flex;
                .jour {
                    width: 50%;
                }
            }
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #444444;
            }
            h4 {
                font-weight: normal;
                font-size: $font-size-24;
                color: #444444;
                text-transform: uppercase;
            }
        }
        .bloc.blocHeures {
            align-items: initial;
            .texte {
                width: 50%;
            }
            h3 {
                padding-bottom: 35px;
            }
            h4 {
                padding-bottom: 10px;
            }
        }
    }
    .right-side {
        width: 50%;
        .blocMap {
            margin-bottom: 60px;
            border-top: 10px solid #38baf5;
            border-bottom: 10px solid #38baf5;
        }
        .section-form {
            position: relative;
            left: 8%;
            h3 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #444444;
                padding-bottom: 35px;
            }
            #right {
                overflow: hidden;
                display: flex;
                .form-group {
                    width: 44%;
                    margin-right: 2%;
                    label.image {
                        width: 100%;
                        margin-bottom: 0;
                        color: #fff;
                    }
                }
            }
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1880px) {

#blocIntro_services {
    background: #ececec;
    min-height: initial;
    padding: 50px 2%;
}
#blocServices {
    background: #38baf5;
    min-height: initial;
    padding: 50px 10%;
}
#blocIntro_equipements {
    background: #38baf5;
    min-height: initial;
    padding: 50px 5%;
}
#blocEquipement, #blocCoordonnees, #blocRealisations {
    background: #ececec;
}
#blocEquipement .middle .image {
    padding-bottom: 40px;
}
}

@media screen and (max-width: 1640px) {
  
nav.module-menu .menu-navbar .wrapper.full-menu .main-menu > ul {
    padding-right: 2rem;
}
nav.module-menu .menu-navbar .wrapper.right {
    padding-right: 1rem;
}
nav.module-menu .menu-navbar .wrapper.full-menu .main-menu > ul li > a div, nav.module-menu .menu-navbar .wrapper.full-menu .main-menu > ul li > .menu-title div {
    font-size: 0.9rem;
}
}

@media screen and (max-width: 1550px) {

#blocIntro_services {
    padding: 50px 5%;
}
#blocIntro_services .left-side {
    width: 45%;
    margin-right: 5%;
}
#blocIntro_services .right-side {
    width: 65%;
}
#blocServices {
    padding: 50px 5%;
}
#blocCoordonnees .left-side .bloc .icon {
    width: 22%;
}
#blocCoordonnees .right-side .section-form {
    position: initial;
}
form label {
    font-size: 1rem;
    width: 40%;
}
}

@media screen and (max-width: 1500px) {

nav.module-menu .menu-navbar .wrapper.right {
    padding-right: 8px;
}
#titrePage {
    position: absolute;
    top: 41%;
    left: 5%;
}
#blocIntro_services .right-side a .texte-image .texte {
    width: 55%;
}
}

@media screen and (max-width: 1385px) {

.bursts .bursts-container .burst .text-container .border p span.hover {
    display: none;
}
#blocIntro_services .right-side {
    width: 60%;
}
#blocIntro_services .right-side a .texte-image .texte {
    width: 76%;
}
}

@media screen and (max-width: $size-md-max) {
  
nav.module-menu .menu-navbar.nav-invisible {
    top: -145px;
}
#titrePage {
    top: 35%;
    left: 5%;
}
#blocEquipement {
    padding: 50px 0;
}
#blocIntro_equipements .right-side a .btnEquip p span {
    right: 20%;
    top: 38%;
}
}

@media screen and (max-width: 1218px) {

#blocOrange {
    background: #38baf5;
    padding: 45px 5%;
}
}

@media screen and (max-width: 1180px) {
   
.bursts .bursts-container .burst .text-container .border p {
    font-size: 1.2rem;
}
#blocOrange .texte p br {
    display: none;
}
#blocIntro_services {
    flex-direction: column;
}
#blocIntro_services .left-side {
    width: 100%;
    margin-right: 0;
    padding-bottom: 25px;
}
#blocIntro_services .right-side {
    width: 75%;
    margin: auto;
}
#blocIntro_services .right-side a .texte-image .texte {
    width: 45%;
}
#blocBleu_services {
    background: #ececec;
}
#blocBleu_services .bloc {
    left: 10%;
}
#blocIntro_equipements {
    flex-direction: column;
}
#blocIntro_equipements .left-side {
    width: 100%;
    padding-bottom: 40px;
}
#blocCoordonnees {
    padding: 100px 2%;
}
#blocCoordonnees .left-side .bloc .icon {
    width: 25%;
}
#blocCoordonnees .left-side .bloc .jours-heures .jour {
    width: 60%;
}
section.gallerie-products div.grid-item {
    width: 30%;
}
}

@media screen and (max-width: 1024px) {
   
.slideshow-wrap .caption .texteBanniere h3 {
    font-size: 2.1rem;
}
.slideshow-wrap .caption .texteBanniere h4 {
    font-size: 1.7rem;
}
.slideshow-wrap .caption {
    top: 73%;
}
.module-header--caption {
    top: 65%;
}
#titrePage h2 {
    font-size: 2rem;
}
#titrePage {
    top: 33%;
    left: 5%;
}
#blocIntro_services .right-side a .texte-image .texte {
    width: 55%;
}
#blocServices .blocliste .left {
    width: 70%;
}
#blocBleu_services .bloc {
    position: initial;
}
#blocBleu_services {
    min-height: initial;
    padding: 50px 3%;
}
#blocEquipement {
    padding: 50px 5%;
    flex-direction: column;
}
#blocEquipement .bloc.blocListe, #blocEquipement .bloc.blocListe2 {
    padding-top: 0;
}
#blocEquipement .middle {
    display: flex;
    flex-wrap: wrap;
}
#blocEquipement .middle .image {
    width: 50%;
    padding: 25px;
}
#blocEquipement .middle .image .img-responsive {
    margin: auto;
}
form label {
    width: 60%;
}
#blocCoordonnees .left-side .bloc h3 {
    font-size: 1.3rem;
}
#blocCoordonnees .left-side .bloc.blocHeures h3 {
    padding-bottom: 25px;
    padding-top: 20px;
}
}

@media screen and (max-width: 1000px) {

nav.module-menu .menu-navbar .wrapper.full-menu .main-menu {
    display: none;
}
}

@media screen and (max-width: $size-sm-max) {
   
.bursts .bursts-container {
    flex-direction: column;
}
.bursts .bursts-container .burst .img-responsive {
    width: 100%;
}
.bursts .bursts-container .burst .text-container .border p {
    font-size: 1.4rem;
}
nav.module-menu .menu-mobile ul.menu li.item a div span.defaut, nav.module-menu .menu-mobile ul.menu li.item a div span.hover {
    display: none;
}
#blocBleu, #blocBleu_services {
    min-height: 650px;
}
#hero .ls-layers .ls-wrapper .ls-layer .title {
    margin-left: -40px;
}
#titrePage h2 {
    font-size: 1.4rem;
}
#titrePage {
    top: 19%;
    left: 5%;
}
#blocIntro_services .right-side {
    width: 95%;
}
#blocCoordonnees {
    flex-direction: column;
}
#blocCoordonnees .left-side, #blocCoordonnees .right-side {
    width: 100%;
}
form label {
    font-size: 1.3rem;
    width: 40%;
}
#blocCoordonnees .left-side .bloc .icon {
    width: 20%;
}
#blocCoordonnees .left-side .bloc h3 {
    font-size: 1.5rem;
}
section.section-menu-filter ul.menu-filter {
    padding: 30px 5%;
}
section.gallerie-products div.grid-item {
    width: 45%;
}
}

@media screen and (max-width: $size-xs-max) {
   
.slideshow-wrap .caption .texteBanniere h4 {
    font-size: 1.6rem;
}
.slideshow-wrap .caption .texteBanniere h3 {
    font-size: 2rem;
}
.slideshow-wrap .caption {
    top: 84%;
    left: 42%;
    width: 100%;
}
#blocOrange {
    padding: 40px 2%;
    background-size: cover;
}
#blocBleu a.btn-equipement {
    width: 95%;
}
#blocBleu {
    background: #ececec;
}
#blocBleu .bloc {
    position: initial;
    flex-direction: column;
    display: block;
    padding: 40px 5%;
}
#blocBleu .bloc .left-side, #blocBleu_services .bloc .left-side {
    padding-right: 0;
    padding-top: 0;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 250px;
}
#hero .ls-layers .ls-wrapper .ls-layer .title {
    padding-left: 15%;
    margin-left: 0;
}
#titrePage h2 {
    font-size: 2rem;
    text-shadow: 0 4px 10px $color-black;
}
.module-header--caption {
    top: 52%;
}
#titrePage {
    top: 50%;
    left: 5%;
}
#blocIntro_services .right-side a .texte-image .image {
    display: none;
}
#blocIntro_services .right-side a .texte-image .texte {
    width: 100%;
}
#blocServices .blocliste {
    flex-direction: column;
}
#blocServices .blocliste .left {
    width: 100%;
    padding-bottom: 25px;
}
#blocBleu_services .bloc {
    flex-direction: column;
    min-height: initial;
    padding: 0 2%;
}
#blocBleu_services p br, #blocEquipement .bloc p br {
    display: none;
}
#blocBleu_services .bloc .left-side .image {
    padding-bottom: 20px;
}
#blocIntro_equipements .right-side a .btnEquip {
    padding: 20px 65px;
}
#blocIntro_equipements .right-side a .btnEquip p span {
    display: none;
}
#blocLogo .logo {
    width: 50%;
}
#blocEquipement .middle {
    padding-bottom: 15px;
}
#blocCoordonnees .left-side .bloc .icon {
    width: 20%;
    margin-right: 5%;
}
#blocCoordonnees .left-side .bloc {
    padding-bottom: 30px;
}
#blocCoordonnees .left-side .bloc.blocHeures h3 {
    padding-bottom: 25px;
}
#blocCoordonnees .left-side .bloc.blocHeures .texte {
    width: 60%;
}
.form-group .flex {
    flex-direction: column;
}
form label.control-label.message {
    padding-top: 0;
}
#blocCoordonnees .right-side .section-form #right {
    flex-direction: column;
}
#blocCoordonnees .right-side .section-form #right .form-group {
    width: 80%;
    margin-right: 0;
    margin-bottom: 10px;
}
#blocCoordonnees {
    padding: 50px 2%;
}
#blocRealisations ul.menu-filter {
    padding: 30px 0%;
}
#blocRealisations ul.menu-filter li {
    padding: 0 10px;
    font-size: 1rem;
}
section.gallerie-products div.grid-item {
    width: 90%;
    margin: 0 20px 50px 20px;
}
}

@media screen and (max-width: 360px) {

#blocBleu_services h3 {
    font-size: 1.2rem;
}
#blocServices {
    padding: 30px 5%;
}
.module-header--caption--title {
    font-size: 1.7rem;
}
.module-header--caption--subtitle {
    font-size: 2.2rem;
}
#blocCoordonnees .left-side .bloc.blocHeures .texte {
    width: 72%;
}
#blocRealisations ul.menu-filter {
    flex-direction: column;
}
#blocRealisations ul.menu-filter li {
    border-right: none;
}
}