.btn, button#submit {
    background-color: #38baf5;
    color: $color-white;
    padding: 5px;
    font-size: $font-size-24;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    transition: all 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-black;
    }
}
