section.section-menu-filter {
    
    ul.menu-filter {
        list-style: none;
        padding: 30px 10%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
            font-size: $font-size-28;
            padding: 0 20px;
            margin: 15px 0;
            line-height: 1;
            font-weight: normal;
            text-transform: uppercase;
            font-size: $font-size-22;
            border-right: 2px solid #38baf5;
            &:last-child{
                border-right: none;
            }
            a {
                color: #444444;
                &:hover, &:focus {
                    color: $color-black;
                    transition: .4s;
                }
            }
        }
    }
}

section.gallerie-products {
    padding: 20px 15px;

    div.container {
        margin: auto;
        padding: 0;
    }

    div.grid-item {
        width: 29%;
        position: relative;
        transition: .5s;
        margin: 0 15px 50px 15px;

        div.overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            visibility: hidden;
            background: rgba(255,255,255, 0.3);
        }
        &:hover {
            div.overlay {
                opacity: 1;
                width: 100%;
                //height: 73%;
                visibility: visible;
                transition: .5s;
            }
            .blocTexte {
                background: #38baf5;
                transition: .5s;
            }
        }
        .blocTexte {
            background: #555;
            padding: 22px;
            transition: .5s;
            p {
                font-weight: normal;
                font-size: $font-size-20;
                color: #fff;
                line-height: 1.3; 
                text-align: center;
                margin: 0;
            }
        }
    }
}
